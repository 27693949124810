import { FirstLevelRoute } from 'routes/constant-route';
import { getSecondLevelRoutes } from './secondLevelNavigations';
import { selectAllBranches, selectRtlsEnabledBranches } from 'store/slices/branches';
import {
  selectAllMainCategories,
  selectClientSettings,
  selectSessionUser
} from 'store/slices/session';
import { styled } from '@borda/cat-mui';
import { useDialogState } from '@borda/cat-core';
import { useFirebaseForegroundMessaging } from 'utils/firebase';
import { useFrill } from './ThirdPartyProducts/useFrill';
import { useInitializeNotificationSocket } from 'hooks/app/notification';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from 'hooks';
import NavBar from './NavBar';
import React, { useMemo } from 'react';
import TeamworkDeskWidget from './ThirdPartyProducts/TeamworkDesk/TeamworkDeskWidget';
import TopBar from 'components/ContentLayout/TopBar';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%'
});

const Page = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  display: 'block',
  maxWidth: '100%',
  paddingLeft: 'var(--left-space)',
  paddingRight: 'var(--right-space)',
  transition: '0.5s',
  width: '100%',
  [theme.breakpoints.up('xl')]: {
    maxWidth: '1800px'
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 'var(--collapsed-left-space)'
  }
}));

const TopbarContainer = styled('div')(({ theme }) => ({
  display: 'block',
  justifySelf: 'center',
  maxWidth: '100%',
  paddingLeft: 'var(--left-menu-width)',
  paddingRight: '8px',
  position: 'sticky',
  top: 'var(--top-space)',
  transition: '0.5s',
  width: '100%',
  zIndex: 1200,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 'var(--collapsed-left-menu-width)'
  }
}));

type Props = {
  children?: React.ReactNode;
};

function Dashboard(props: Props) {
  const { children } = props;

  useFirebaseForegroundMessaging();
  useInitializeNotificationSocket();

  const sessionUser = useTypedSelector(selectSessionUser);
  const mainCategories = useTypedSelector(selectAllMainCategories);
  const userBranches = useTypedSelector(selectAllBranches);
  const rtlsEnabledBranches = useTypedSelector(selectRtlsEnabledBranches);
  const { cyleraEnable } = useTypedSelector(selectClientSettings);

  const location = useLocation();

  const { isOpen, togglePopup } = useDialogState();

  const pathNameParts = location.pathname.split('/');
  const secondLevelExists = pathNameParts.length > 2;

  const secondLevelRoutes = useMemo(
    () =>
      secondLevelExists &&
      getSecondLevelRoutes({
        cyleraEnable,
        firstLevelRoute: pathNameParts[1] as FirstLevelRoute,
        mainCategories,
        rtlsEnabledBranches,
        user: sessionUser,
        userBranches
      }),
    [
      cyleraEnable,
      mainCategories,
      pathNameParts,
      rtlsEnabledBranches,
      secondLevelExists,
      sessionUser,
      userBranches
    ]
  );

  useFrill();

  return (
    <Root>
      <NavBar />
      <Container>
        <TopbarContainer>
          <TopBar
            assetSearchDialogOpen={isOpen}
            routes={secondLevelRoutes}
            toggleAssetSearchDialog={togglePopup}
          />
        </TopbarContainer>
        <Page>
          {children}
          <TeamworkDeskWidget />
        </Page>
      </Container>
    </Root>
  );
}

export default Dashboard;
