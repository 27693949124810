import { AssetObject } from 'views/Alert/types';
import { CatTypography } from '@borda/cat-ui';

type Props = Omit<AssetObject, 'assetStatus' | 'modelPhotoPath' | 'activeTag'>;

function Asset(props: Props) {
  const { brandName, categoryName, code, modelName } = props;
  const nameItems = [categoryName, brandName, modelName];

  return (
    <>
      {nameItems.map((name) => (
        <div className="flex items-center gap-1">
          <CatTypography className="max-w-[100px] truncate" title={name} variant="body2">
            {name}
          </CatTypography>
          <CatTypography variant="body2">|</CatTypography>
        </div>
      ))}
      <CatTypography className="font-semibold" title={code} variant="body2">
        {code}
      </CatTypography>
    </>
  );
}

export default Asset;
