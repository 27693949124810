import { CatTypography } from '@borda/cat-ui';
import { UserObject } from 'views/Alert/types';

type Props = Pick<UserObject, 'firstName' | 'lastName' | 'jobTitle'>;

function Patient(props: Props) {
  const { firstName, jobTitle, lastName } = props;

  return (
    <>
      <CatTypography
        className="max-w-72 truncate font-bold"
        title={`${firstName} ${lastName}`}
        variant="body2"
      >
        {firstName} {lastName}
      </CatTypography>
      <CatTypography className="max-w-24 truncate" title={jobTitle} variant="body2">
        | {jobTitle || '-'}
      </CatTypography>
    </>
  );
}

export default Patient;
