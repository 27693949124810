import { AssetStatus } from 'store/slices/common/types';
import {
  BreakdownIcon,
  CatIconBaseProps,
  CheckIcon,
  DownIcon,
  PassiveIcon,
  RetireIcon,
  WarningIcon
} from '@borda/cat-icons';
import { CatTooltip } from '@borda/cat-ui';
import { Colors } from '@borda/cat-core';
import { useCatTranslation } from 'utils/localization';
import React from 'react';
import clsx from 'clsx';

type Props = CatIconBaseProps & {
  className?: string;
  statusType: AssetStatus;
  tooltipTitle?: string;
};

function StatusIcon(props: Props) {
  const { className, statusType, tooltipTitle, ...rest } = props;
  const { t } = useCatTranslation();

  const [IconComponent, iconColor] = getStatusIconComponent(statusType);

  const title = tooltipTitle || t(`assets.statuses.${statusType}`);

  return (
    <CatTooltip title={title}>
      <div>
        <IconComponent
          alwaysHovered
          className={clsx(className, 'flex')}
          color={iconColor}
          {...rest}
        />
      </div>
    </CatTooltip>
  );
}

export const getStatusIconComponent = (
  statusType: AssetStatus
): [React.ComponentType<CatIconBaseProps>, Colors] => {
  let IconComponent;
  let iconColor: Colors;

  switch (statusType) {
    case 'usable':
      IconComponent = CheckIcon;
      iconColor = 'green';
      break;
    case 'caution':
      IconComponent = WarningIcon;
      iconColor = 'yellow';
      break;
    case 'partialDown':
      IconComponent = BreakdownIcon;
      iconColor = 'orange';
      break;
    case 'down':
      IconComponent = DownIcon;
      iconColor = 'red';
      break;
    case 'passive':
      IconComponent = PassiveIcon;
      iconColor = 'darkGrey';
      break;
    case 'retired':
      IconComponent = RetireIcon;
      iconColor = 'darkGrey';
      break;
    default:
      IconComponent = CheckIcon;
      iconColor = 'green';
      break;
  }

  return [IconComponent, iconColor];
};

export default StatusIcon;
