import { ALERTLIST } from 'helpers/localStorage/storageConstant';
import { AlertPanelFilter } from './types';
import { defaultAlertPanelFilter } from './data';
import { getFilterKey } from 'helpers/localStorage';
import { loadFromLocalStorage, mergeWithShape } from '@borda/cat-core';
import { useMemo } from 'react';

type MergeAndValidateFilterResult = { filter: AlertPanelFilter; isValid: boolean };
const mergeWithDefaultAndValidateFilter = (
  filter: AlertPanelFilter,
  schema: any
): MergeAndValidateFilterResult => {
  const mergedFilter = mergeWithShape(filter, defaultAlertPanelFilter);
  const isValid = schema.isValidSync(mergedFilter);

  return { filter: mergedFilter, isValid };
};

export const useGetFilterFromLocalStorage = (userId: string, schema: any) =>
  useMemo(() => {
    const storedFilter = loadFromLocalStorage(getFilterKey(ALERTLIST), userId) as AlertPanelFilter;

    if (!storedFilter) {
      return defaultAlertPanelFilter;
    }

    const { filter: mergedFilter, isValid } = mergeWithDefaultAndValidateFilter(
      storedFilter,
      schema
    );

    if (!isValid) {
      return defaultAlertPanelFilter;
    }

    return mergedFilter;
  }, [schema, userId]);
