const listUrls = {
  getCalibrationIds: () => '/api/calibration/calibrations/search/ids',
  getCalibrationList: () => '/api/calibration/calibrations/search',
  getMaintenanceIds: () => '/api/maintenance/maintenances/search/ids',
  getMaintenanceList: () => '/api/maintenance/maintenances/search'
};

const substatusUrls = {
  addCalibrationSubstatus: () => '/api/calibration/calibrationtasksubstatuses',
  addMaintenanceSubstatus: () => '/api/maintenance/maintenancetasksubstatuses',
  deleteCalibrationSubstatus: (id: string) => `/api/calibration/calibrationtasksubstatuses/${id}`,
  deleteMaintenanceSubstatus: (id: string) => `/api/maintenance/maintenancetasksubstatuses/${id}`,
  getConfigurableCalibrationSubstatuses: () =>
    '/api/calibration/calibrationtasksubstatuses/configurable',
  getConfigurableMaintenanceSubstatuses: () =>
    '/api/maintenance/maintenancetasksubstatuses/configurable',
  getSelectableCalibrationSubstatuses: (mainCategoryId: string = null) =>
    `/api/calibration/calibrationtasksubstatuses/selectable?mainCategoryId=${mainCategoryId}`,
  getSelectableMaintenanceSubstatuses: (mainCategoryId: string = null) =>
    `/api/maintenance/maintenancetasksubstatuses/selectable?mainCategoryId=${mainCategoryId}`,
  updateBulkCalibrationStatus: () => '/api/calibration/calibrations/update-statuses',
  updateBulkMaintenanceStatus: () => '/api/maintenance/maintenances/update-statuses',
  updateCalibrationSubstatus: (id: string) => `/api/calibration/calibrationtasksubstatuses/${id}`,
  updateMaintenanceSubstatus: (id: string) => `/api/maintenance/maintenancetasksubstatuses/${id}`
};

const costUrls = {
  addCalibrationCost: () => '/api/calibration/calibrationcosttypes',
  addMaintenanceCost: () => '/api/maintenance/maintenancecosttypes',
  deleteCalibrationCost: (id: string) => `/api/calibration/calibrationcosttypes/${id}`,
  deleteMaintenanceCost: (id: string) => `/api/maintenance/maintenancecosttypes/${id}`,
  getCalibrationCosts: () => '/api/calibration/calibrationcosttypes',
  getMaintenanceCosts: () => '/api/maintenance/maintenancecosttypes',
  updateCalibrationCost: (id: string) => `/api/calibration/calibrationcosttypes/${id}`,
  updateMaintenanceCost: (id: string) => `/api/maintenance/maintenancecosttypes/${id}`
};

const protectionDetailCostUrls = {
  addCalibrationDetailCost: (id: string) => `/api/calibration/calibrations/${id}/costs`,
  addMaintenanceDetailCost: (id: string) => `/api/maintenance/maintenances/${id}/costs`,
  deleteCalibrationDetailCost: (id: string, costId: string) =>
    `/api/calibration/calibrations/${id}/costs/${costId}`,
  deleteMaintenanceDetailCost: (id: string, costId: string) =>
    `/api/maintenance/maintenances/${id}/costs/${costId}`,
  updateCalibrationDetailCost: (id: string, costId: string) =>
    `/api/calibration/calibrations/${id}/costs/${costId}`,
  updateMaintenanceDetailCost: (id: string, costId: string) =>
    `/api/maintenance/maintenances/${id}/costs/${costId}`
};

const protectionDetailFileUrls = {
  deleteCalibrationFile: (id: string, fileId: string) =>
    `/api/calibration/calibrations/${id}/file/${fileId}`,
  deleteMaintenanceFile: (id: string, fileId: string) =>
    `/api/maintenance/maintenances/${id}/file/${fileId}`,
  downloadCalibrationFile: (id: string, fileId: string) =>
    `/api/calibration/calibrations/${id}/files/${fileId}`,
  downloadMaintenanceFile: (id: string, fileId: string) =>
    `/api/maintenance/maintenances/${id}/files/${fileId}`,
  updateCalibrationFile: (id: string) => `/api/calibration/calibrations/${id}/files`,
  updateMaintenanceFile: (id: string) => `/api/maintenance/maintenances/${id}/files`,
  uploadCalibrationBulkFile: () => `/api/calibration/calibrations/upload-files`,
  uploadCalibrationFile: (endpoint: string) => `/api/calibration/calibrations/${endpoint}`,
  uploadMaintenanceBulkFile: () => `/api/maintenance/maintenances/upload-files`,
  uploadMaintenanceFile: (endpoint: string) => `/api/maintenance/maintenances/${endpoint}`
};

const protectionDetailStatusUrls = {
  updateCalibrationDetailStatus: (id: string) => `/api/calibration/calibrations/${id}/status`,
  updateMaintenanceDetailStatus: (id: string) => `/api/maintenance/maintenances/${id}/status`
};

const protectionDetailAssignmentUrls = {
  updateBulkCalibrationAssignment: () => '/api/calibration/calibrations/assign-tasks',
  updateBulkMaintenanceAssignment: () => '/api/maintenance/maintenances/assign-tasks',
  updateCalibrationAssignment: (id: string) => `/api/calibration/calibrations/${id}/assignment`,
  updateMaintenanceAssignment: (id: string) => `/api/maintenance/maintenances/${id}/assignment`
};

const protectionDetailCommitUrls = {
  commitCalibrationDetail: (id: string) => `/api/calibration/calibrations/${id}/commit`,
  commitMaintenanceDetail: (id: string) => `/api/maintenance/maintenances/${id}/commit`
};

const protectionDetailExpiryDateUrls = {
  updateBulkCalibrationExpiryDate: () => '/api/calibration/calibrations/update-expiry-dates',
  updateBulkMaintenanceExpiryDate: () => '/api/maintenance/maintenances/update-expiry-dates',
  updateCalibrationExpiryDate: (id: string) => `/api/calibration/calibrations/${id}/expiry-date`,
  updateMaintenanceExpiryDate: (id: string) => `/api/maintenance/maintenances/${id}/expiry-date`
};

const protectionCalendarUrls = {
  getCompletionSummary: () => `/api/protection/protectioncalendars/completion-summary`,
  getSamplePlannedDates: () => `/api/protection/protectioncalendars/sample-planned-dates`,
  moveItemUnscheduledToCalendar: (scheduledItemId: string) =>
    `/api/protection/protectioncalendars/${scheduledItemId}/move-to-scheduled-list`,
  moveTaskCalendarToUnscheduled: (taskId: string) =>
    `/api/protection/protectioncalendars/${taskId}/move-to-unscheduled-list`,
  updateCalendarTasks: (taskId: string) =>
    `/api/protection/protectioncalendars/${taskId}/update-scheduled-dates`
};
const userSearchUrls = {
  authorizedUsersByAssetIdCalibration: () =>
    `/api/calibration/calibrations/assignable-persons/search`,
  authorizedUsersByAssetIdMaintenance: () =>
    `/api/maintenance/maintenances/assignable-persons/search`
};

const noteUrls = {
  addCalibrationNote: (protectionId: string) =>
    `/api/calibration/calibrations/${protectionId}/notes`,
  addMaintenanceNote: (protectionId: string) =>
    `/api/maintenance/maintenances/${protectionId}/notes`,
  deleteCalibrationNote: (protectionId: string, noteId: string) =>
    `/api/calibration/calibrations/${protectionId}/notes/${noteId}`,
  deleteMaintenanceNote: (protectionId: string, noteId: string) =>
    `/api/maintenance/maintenances/${protectionId}/notes/${noteId}`
};

export default {
  ...listUrls,
  ...substatusUrls,
  ...costUrls,
  ...protectionDetailCostUrls,
  ...protectionDetailFileUrls,
  ...protectionDetailStatusUrls,
  ...protectionDetailAssignmentUrls,
  ...protectionDetailCommitUrls,
  ...protectionDetailExpiryDateUrls,
  ...protectionCalendarUrls,
  ...userSearchUrls,
  ...noteUrls
};
