import { CatTrans, useCatTranslation } from 'utils/localization';
import { CatTypography } from '@borda/cat-ui';
import { ObjectDetails, ProductType } from 'views/Alert/types';
import Asset from './Asset';
import Patient from './Patient';
import Staff from './Staff';

type Props = {
  objectDetails: ObjectDetails;
};

type AlertObjectDefinition = {
  objectElement: JSX.Element;
  product: ProductType;
};

function AlertObjectName(props: Props) {
  const { objectDetails } = props;
  const { asset, patient, user } = objectDetails ?? {};

  const { t: tAlertProducts } = useCatTranslation('translation', {
    keyPrefix: 'alert.productTypes'
  });

  const getAlertObjectDefinitions = (): AlertObjectDefinition => {
    if (asset) {
      return {
        objectElement: <Asset {...asset} />,
        product: 'asset'
      };
    }

    if (user) {
      return {
        objectElement: <Staff {...user} />,
        product: 'staff'
      };
    }

    return {
      objectElement: <Patient {...patient} />,
      product: 'patient'
    };
  };

  const { objectElement, product } = getAlertObjectDefinitions();

  return (
    <div className="flex items-center gap-0.5">
      <CatTypography className="opacity-80" variant="body2">
        <CatTrans i18nKey={product} t={tAlertProducts} />:
      </CatTypography>
      <div className="flex items-center gap-1">{objectElement}</div>
    </div>
  );
}

export default AlertObjectName;
