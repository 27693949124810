import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';

const TaskList = delayedLazy(() => import('views/Tasks/List/List'));
const ProtectionList = delayedLazy(() => import('views/Tasks/Protection/List/List'));
const BreakdownTaskDetail = delayedLazy(
  () => import('views/Tasks/Breakdowns/Detail/TaskDetailPage')
);
const WfcList = delayedLazy(
  () => import('views/Tasks/WaitingForConfirmation/WaitingForConfirmation')
);
const BreakdownWfcDetail = delayedLazy(
  () => import('views/Tasks/Breakdowns/WaitingForConfirmationDetail/WaitingForConfirmationPage')
);

const ProtectionDetailPage = delayedLazy(
  () => import('views/Tasks/Protection/Detail/ProtectionDetailPage')
);

export default function renderTaskRoutes() {
  return (
    <Route
      element={
        <RouteGuard products={['asset']}>
          <SuspenseOutlet />
        </RouteGuard>
      }
      path="task"
    >
      <Route
        element={
          <RouteGuard authKey="taskList">
            <Outlet />
          </RouteGuard>
        }
        path="tasks"
      >
        <Route element={<TaskList />} index />
        <Route
          element={<ProtectionList protectionType="calibration" />}
          path="calibrations/:protectionCalendarId"
        />
        <Route
          element={<ProtectionList protectionType="maintenance" />}
          path="maintenances/:protectionCalendarId"
        />
        <Route element={<BreakdownTaskDetail />} path="breakdown/:breakdownId" />
        <Route
          element={<ProtectionDetailPage protectionType="maintenance" />}
          path="maintenance/:protectionId"
        />
        <Route
          element={<ProtectionDetailPage protectionType="calibration" />}
          path="calibration/:protectionId"
        />
      </Route>
      <Route
        element={
          <RouteGuard authKey="wfcList">
            <Outlet />
          </RouteGuard>
        }
        path="waiting-for-confirmation"
      >
        <Route element={<WfcList />} index />
        <Route element={<BreakdownWfcDetail />} path="breakdown/:breakdownId" />
      </Route>
      <Route element={<Navigate replace to="tasks" />} index />
    </Route>
  );
}
