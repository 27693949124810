import { AvatarProps, CatAvatar } from '@borda/cat-ui';
import { selectUserLanguage } from 'store/slices/session';
import { useMemo } from 'react';
import { useTypedSelector } from 'hooks';

type Props = AvatarProps & {
  user: { firstName?: string; lastName?: string };
};

function AvatarItem(props: Props) {
  const { className, user, ...rest } = props;
  const language = useTypedSelector(selectUserLanguage);

  const firstName = user?.firstName;
  const lastName = user?.lastName;

  const renderLetters = useMemo(() => {
    // TODO: Change this locale information
    const firstLetter = firstName?.[0] ?? '';
    const secondLetter = lastName?.[0] ?? '';

    return (firstLetter + secondLetter).toLocaleUpperCase(language);
  }, [firstName, lastName, language]);

  if (!user) {
    return null;
  }

  return (
    <CatAvatar alt={`${firstName} ${lastName}`} className={className} src={null} {...rest}>
      {renderLetters}
    </CatAvatar>
  );
}

export default AvatarItem;
