import { CatTypography } from '@borda/cat-ui';
import { PatientObject } from 'views/Alert/types';

type Props = Pick<PatientObject, 'firstName' | 'lastName' | 'protocolNo'>;

function Patient(props: Props) {
  const { firstName, lastName, protocolNo } = props;

  return (
    <>
      <CatTypography
        className="max-w-72 truncate font-bold"
        title={`${firstName} ${lastName}`}
        variant="body2"
      >
        {firstName} {lastName}
      </CatTypography>
      <CatTypography className="max-w-24 truncate" title={protocolNo} variant="body2">
        | {protocolNo || '-'}
      </CatTypography>
    </>
  );
}

export default Patient;
