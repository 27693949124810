import { AlertInProgressIcon, AlertOpenIcon, CheckIcon } from '@borda/cat-icons';
import { AlertPanelFilter, AlertStatusCounts } from '../types';
import { CatToggleButton } from '@borda/cat-ui';
import { ResponseStatuses } from 'views/Alert/types';
import { useCatTranslation } from 'utils/localization';
import { useController, useFormContext } from 'react-hook-form';
import HeaderContainer from '../Common/HeaderContainer';

type Props = {
  isSuccess: boolean;
  statusCounts: AlertStatusCounts;
};

function QuickStatusFilters(props: Props) {
  const { isSuccess, statusCounts } = props;

  const { t: tAlert } = useCatTranslation('translation', {
    keyPrefix: 'alert.statuses'
  });

  const { control } = useFormContext<AlertPanelFilter>();

  const {
    field: { onChange: onStatusChange, value: selectedStatuses }
  } = useController({ control, name: 'statuses' });

  const toggleQuickFilter = (status: ResponseStatuses) => {
    const newStatus = [...selectedStatuses];
    const index = newStatus.findIndex((i) => i === status);

    if (index > -1) {
      newStatus.splice(index, 1);
    } else {
      newStatus.push(status);
    }

    onStatusChange(newStatus);
  };

  const isFilterSelected = (status: ResponseStatuses) => selectedStatuses.includes(status);

  const renderStatusCount = (status: ResponseStatuses) => {
    if (!isSuccess) {
      return '-';
    }

    const { closed, inProgress, open } = statusCounts;

    const statusCountMap: AlertStatusCounts = {
      closed,
      inProgress,
      open
    };

    return `${statusCountMap[status]}`;
  };

  return (
    <HeaderContainer className="py-2">
      <CatToggleButton
        color="red"
        icon={<AlertOpenIcon />}
        loading={!isSuccess}
        onClick={() => toggleQuickFilter('open')}
        selected={isFilterSelected('open')}
        subtitle={renderStatusCount('open')}
        title={tAlert('open')}
      />
      <CatToggleButton
        color="orange"
        icon={<AlertInProgressIcon />}
        loading={!isSuccess}
        onClick={() => toggleQuickFilter('inProgress')}
        selected={isFilterSelected('inProgress')}
        subtitle={renderStatusCount('inProgress')}
        title={tAlert('inProgress')}
      />
      <CatToggleButton
        color="green"
        icon={<CheckIcon />}
        loading={!isSuccess}
        onClick={() => toggleQuickFilter('closed')}
        selected={isFilterSelected('closed')}
        subtitle={renderStatusCount('closed')}
        title={tAlert('closed')}
      />
    </HeaderContainer>
  );
}

export default QuickStatusFilters;
