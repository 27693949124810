import { AlertPanelFilter } from './AlertPanel/types';

export const alertKeys = {
  all: () => ['alerts'],
  dialog: {
    all: () => [...alertKeys.all(), 'dialog'],
    detail: (alertId: string) => [...alertKeys.dialog.all(), alertId]
  },
  panel: {
    all: () => [...alertKeys.all(), 'panel'],
    list: {
      all: () => [...alertKeys.panel.all(), 'list'],
      byFilter: (alertFilter: AlertPanelFilter) => [...alertKeys.panel.list.all(), alertFilter]
    }
  }
};
