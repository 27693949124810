import { AlertPanelFilter } from 'views/Alert/AlertPanel/types';
import { defaultAlertPanelFilter } from 'views/Alert/AlertPanel/data';
import { trackMixpanelEvent } from 'utils/mixpanel';
import { useFormContext } from 'react-hook-form';
import CommonBranchFilter from 'components/Branch/BranchFilter/BranchFilter';

function BranchFilter() {
  const { setValue, watch } = useFormContext<AlertPanelFilter>();

  const { branches: selectedBranches } = watch();

  const handleBranchChange = (branchIds: string[]) => {
    setValue('branches', branchIds, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const handleBranchClose = (isChanged: boolean) => {
    trackMixpanelEvent(isChanged ? 'AlertPanel_BranchIsFiltered' : 'AlertPanel_BranchIsClosed');
  };

  const handleBranchOpen = () => {
    trackMixpanelEvent('AlertPanel_BranchTriggered');
  };

  return (
    <CommonBranchFilter
      defaultBranchIds={defaultAlertPanelFilter.branches}
      onChange={handleBranchChange}
      onClose={handleBranchClose}
      onOpen={handleBranchOpen}
      selectedBranchIds={selectedBranches}
    />
  );
}

export default BranchFilter;
