import { AlertStatusCounts } from '../types';
import FiltersAndSort from './FiltersAndSort/FiltersAndSort';
import Header from './Header';
import QuickStatusFilters from './QuickStatusFilters';

type Props = {
  isSuccess: boolean;
  onClose: () => void;
  statusCounts: AlertStatusCounts;
};

function PanelHeader(props: Props) {
  const { onClose, ...rest } = props;

  return (
    <div>
      <Header onClose={onClose} />
      <QuickStatusFilters {...rest} />
      <FiltersAndSort />
    </div>
  );
}

export default PanelHeader;
