import { alertPanelSortings, extraFilters } from './types';
import { priorities, productTypes, responseStatuses } from '../types';
import { selectAllBranchIds } from 'store/slices/branches';
import { useMemo } from 'react';
import { useTypedSelector } from 'hooks';
import yup from 'utils/yup/yupExtended';

// TODO :sortSchema waiting one of the alertPanelSortings value but initialvalue is null
// so because of that mergeWithDefaultAndValidateFilter func. isValid return false.
// giving initial sorting value is okey, but an undesirable behavior
// talk with team...
const sortSchema = yup
  .string()
  .nullable()
  .oneOf([...alertPanelSortings]);

export const useSchema = () => {
  const branchIds = useTypedSelector(selectAllBranchIds);

  const schema = useMemo(
    () =>
      yup.object().shape({
        branches: yup
          .array()
          .of(yup.string().oneOf([...branchIds]))
          .nullable(),
        extraFilters: yup
          .array()
          .of(yup.string().oneOf([...extraFilters]))
          .nullable(),
        priorities: yup
          .array()
          .of(yup.string().oneOf([...priorities]))
          .nullable(),
        products: yup
          .array()
          .of(yup.string().oneOf([...productTypes]))
          .nullable(),
        statuses: yup
          .array()
          .of(yup.string().oneOf([...responseStatuses]))
          .nullable()
      }),
    [branchIds]
  );

  return schema;
};
