const formFieldUrls = {
  addFormField: (formId: string) => `/api/registration/forms/${formId}/fields/`,
  deleteFormField: (formId: string, formFieldId: string) =>
    `/api/registration/forms/${formId}/fields/${formFieldId}/`,
  getFormBuilderIdentificationFields: () => `/api/registration/forms/fields/searchable/`,
  getFormFieldsByMainCategoryId: (mainCategoryId: string) =>
    `/api/registration/forms/main-category/${mainCategoryId}/fields`,
  orderFormFields: (formId: string) => `/api/registration/forms/${formId}/order-fields/`,
  updateFormField: (formId: string, formFieldId: string) =>
    `/api/registration/forms/${formId}/fields/${formFieldId}/`
};

const assetUrls = {
  addAsset: () => '/api/registration/assets',
  assignMacAddressToAsset: (assetId: string) =>
    `/api/registration/assets/${assetId}/assign-device-mac-address`,
  deleteAssets: () => `/api/registration/assets`,
  disassociatePlanFromAsset: (assetId: string) =>
    `/api/registration/assets/${assetId}/disassociate-plan`,
  getAssetPurchasedFirms: () => '/api/registration/assets/purchased-firms',
  getAssetStatusStatistics: () => '/api/registration/assets/cached-status-statistics',
  getAssetsByTaskId: (taskId: string) => `/api/registration/assets/by-categorybrandmodel/${taskId}`,
  transfer: () => `/api/registration/assets/transfer`,
  unassignActiveTag: (assetId: string) => `/api/registration/assets/${assetId}/unassign-active-tag`,
  updateAsset: (assetId: string) => `/api/registration/assets/${assetId}`,
  updateAssetsBulkEdit: () => '/api/registration/assets'
};

const assetStatusUrls = {
  updateAssetStatusActive: () => '/api/registration/assets/active',
  updateAssetStatusPassive: () => '/api/registration/assets/passive'
};

const disassociateUrls = {
  disassociateAssetsFromContracts: () => `/api/registration/assets/disassociate-all-contracts`,
  disassociateAssetsFromPlans: () => `/api/registration/assets/disassociate-all-plans`,
  disassociateContractFromAllAssets: (contractId: string) =>
    `/api/registration/assets/disassociate/contracts/${contractId}`,
  disassociateContractFromAsset: (assetId: string) =>
    `/api/registration/assets/${assetId}/disassociate-contract`,
  disassociatePlanFromAllAssets: (planId: string) =>
    `/api/registration/plans/${planId}/disassociate-all-assets`,
  getContractDisassociateAssetCounts: () => `/api/registration/contracts/associated-asset-counts`,
  getPlanDisassociateAssetCounts: () => `/api/registration/plans/associated-asset-counts`
};

const searchUrls = {
  getAssetSearchResults: () => `/api/registration/assets/search/`,
  searchAssetCodes: () => `/api/registration/assets/codes/search`
};

const assetFilesUrls = {
  deleteAssetFile: (assetId: string, fileId: string) =>
    `/api/registration/assets/${assetId}/file/${fileId}`,
  downloadAssetFile: (assetId: string, fileId: string) =>
    `/api/registration/assets/${assetId}/files/${fileId}`,
  updateAssetFile: (assetId: string) => `/api/registration/assets/${assetId}/files`,
  uploadAssetFile: (endpoint: string) => `/api/registration/assets/${endpoint}`
};

const contractUrls = {
  addContract: () => '/api/registration/contracts',
  deleteContract: (contractId: string) => `/api/registration/contracts/${contractId}`,
  downloadContractFile: (contractId: string, downloadPath: string) =>
    `/api/registration/contracts/${contractId}/files/${downloadPath}`,
  getContractAssetCount: (contractId: string) =>
    `/api/registration/contracts/${contractId}/asset-and-plan/counts`,
  getContractAssociatedAssetCounts: (contractId: string) =>
    `/api/registration/contracts/${contractId}/asset-counts`,
  getContractBasics: (contractId: string) => `/api/registration/contracts/${contractId}/basics`,
  getContractFirmContactPersons: () => '/api/registration/contracts/firm-contact-persons',
  getContractFirmNames: () => `/api/registration/contracts/firm-names`,
  getContractSearchResults: () => `/api/registration/contracts/search/`,
  getContractSummary: (contractId: string) => `/api/registration/contracts/${contractId}/summary`,
  getFilteredContracts: () => '/api/registration/contracts/filter',
  getTaskStatuses: (mainCategoryId: string) =>
    `/api/registration/taskstatuses/filter?mainCategoryId=${mainCategoryId}`,
  updateContract: (contractId: string) => `/api/registration/contracts/${contractId}`,
  uploadTemporaryContractFile: () => `/api/registration/contracts/temporary-files`
};

const planUrls = {
  addPlan: () => '/api/registration/plans',
  deletePlan: (planId: string) => `/api/registration/plans/${planId}`,
  getAllPlans: () => `/api/registration/plans/basics`,
  getAuthorizedToEditPlan: (planId: string) =>
    `/api/registration/plans/${planId}/authorized-on-edit`,
  getPlanAssetAndBranchCounts: (planId: string) =>
    `/api/registration/plans/${planId}/asset-and-branch/count`,
  getPlanAssetCounts: (planId: string) => `/api/registration/plans/${planId}/asset/count`,
  getPlanAssociatedAssetCounts: (planId: string) =>
    `/api/registration/plans/${planId}/asset-counts`,
  getPlanAuthorization: (mainCategoryId: string, protectionType: string) =>
    `/api/registration/plans/main-category/${mainCategoryId}/plan-type/${protectionType}`,
  getPlanTitles: (planType: string) => `/api/registration/plans/titles/${planType}`,
  updatePlanGeneralInformation: (planId: string) => `/api/registration/plans/${planId}`,
  updatePlanPersonnels: (planId: string) =>
    `/api/registration/plans/${planId}/assignment-and-notified-personnel`
};

const associateUrls = {
  associateAssetsWithContract: () => `/api/registration/assets/associate-contract`,
  associateAssetsWithPlan: () => `/api/registration/assets/associate-plan`,
  getIsAssociationLimitReachedOnContractType: (planyType: string) =>
    `/api/registration/assets/is-association-limit-reached-on-contract-type/${planyType}`,
  getIsAssociationLimitReachedOnPlanType: (contractType: string) =>
    `/api/registration/assets/is-association-limit-reached-on-plan-type/${contractType}`,
  getPlanNotRequiredCategories: (planType: string) =>
    `/api/registration/assets/search/plan-not-required/${planType}`,
  getSelectableContracts: () => `/api/registration/contracts/matching-assets`,
  getSelectablePlans: () => `/api/registration/plans/matching-assets`
};

const socketUrls = {
  connectAssetDetailSocket: (assetId: string) =>
    `/socket/registration/hubs/asset?assetId=${assetId}`
};

const custodyUrls = {
  approveCustody: () => '/api/registration/assets/custody-assignments/approve',
  denyCustody: () => '/api/registration/assets/custody-assignments/deny',
  getCustodyAssignmentCount: () => '/api/registration/assets/custody-assignments/count',
  getUserCustodyAssignmentCounts: (userId: string) =>
    `/api/registration/assets/custody-assignments/${userId}/counts/on-branches`
};

const metricsConfigurationUrls = {
  getMetricTypes: (mainCategoryId: string) =>
    `/api/registration/assetmetrics/${mainCategoryId}/by-main-category`,
  getMetricsConfiguration: (metricId: string) => `/api/registration/assetmetrics/${metricId}`,
  updateMetricsConfiguration: (metricId: string) => `/api/registration/assetmetrics/${metricId}`
};

const listingUrls = {
  deleteSavedFilter: (filterId: string) => `/api/registration/filters/${filterId}`,
  getAssetActionsValidation: () => `/api/registration/assets/check-assets-actions`,
  getAssetList: () => `/api/registration/assets/filter/selectable-columns`,
  getAssetListLocationSignalErrors: () => `/api/registration/assets/filter/location-signal-errors`,
  getAssetListSavedFilters: () => `/api/registration/filters`,
  getAssetValuesForBulkEdit: () => '/api/registration/assets/asset-values-for-bulk-edit',
  getAssetsByAssetIds: () => `/api/registration/assets/search/by-ids/detail`,
  getAssetsByCategoryBrandModelId: (categoryTaskId: string) =>
    `/api/registration/assets/by-categorybrandmodel/${categoryTaskId}/detail`,
  getCustodyAssignmentRequests: () => '/api/registration/assets/custody-assignments',
  getMyAssetListLocationSignalErrors: () =>
    `/api/registration/my-assets/filter/location-signal-errors`,
  getMyAssetsList: () => `/api/registration/my-assets/filter/fixed-columns`,
  saveFilter: () => `/api/registration/filters`
};

export default {
  ...formFieldUrls,
  ...assetUrls,
  ...assetFilesUrls,
  ...contractUrls,
  ...planUrls,
  ...associateUrls,
  ...disassociateUrls,
  ...searchUrls,
  ...assetStatusUrls,
  ...socketUrls,
  ...custodyUrls,
  ...metricsConfigurationUrls,
  ...listingUrls
};
