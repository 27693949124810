import {
  AlertPanelFilter,
  AlertPanelSort as AlertPanelSortTypes,
  alertPanelSortings
} from '../../types';
import {
  CatIconButton,
  CatMenu,
  CatMenuDivider,
  CatMenuItem,
  CatRadio,
  CatTypography
} from '@borda/cat-ui';
import { CatTrans, useCatTranslation } from 'utils/localization';
import { FilterListIcon } from '@borda/cat-icons';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { trackMixpanelEvent } from 'utils/mixpanel';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

function AlertPanelSort() {
  const { t: tAlertPanelSorts } = useCatTranslation('translation', {
    keyPrefix: 'alert_panel.header.sorts'
  });

  const popupState = usePopupState({ popupId: 'alertPanelSorts', variant: 'popover' });

  const { setValue, watch } = useFormContext<AlertPanelFilter>();
  const { sort: selectedSort } = watch();

  const [localSelectedSort, setLocalSelectedSort] = useState<AlertPanelSortTypes>(selectedSort);

  useEffect(() => {
    setLocalSelectedSort(selectedSort);
  }, [selectedSort]);

  const handleSortClick = (sort: AlertPanelSortTypes) => {
    setLocalSelectedSort(sort);
  };
  const isAnySortSelected = selectedSort;

  const handleClose = () => {
    popupState.close();

    const isChanged = localSelectedSort !== selectedSort;
    trackMixpanelEvent(isChanged ? 'AlertPanel_AlertsAreSorted' : 'AlertPanel_SortsMenuIsClosed');

    setValue('sort', localSelectedSort, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  return (
    <>
      <CatIconButton disabled {...bindTrigger(popupState)}>
        <div className="relative flex items-center">
          <FilterListIcon alwaysHovered={!!isAnySortSelected} color="darkGrey" contained />
        </div>
      </CatIconButton>
      <CatMenu {...bindMenu(popupState)} addEmptyFirstItem onClose={handleClose} width="292px">
        <CatTypography className="mx-4 opacity-80" variant="body1">
          <CatTrans i18nKey="title" t={tAlertPanelSorts} />
        </CatTypography>
        <CatMenuDivider />
        {alertPanelSortings.map((sort, index) => {
          const checked = sort === localSelectedSort;
          const isLastItem = index === alertPanelSortings.length - 1;

          return (
            <div key={sort}>
              <CatMenuItem onClick={() => handleSortClick(sort)}>
                <CatRadio checked={checked} id={sort} name={sort} size="medium" zeroPadding />
                <CatTypography
                  className={clsx(checked ? 'font-bold' : '', 'truncate')}
                  variant="body2"
                >
                  <CatTrans i18nKey={`options.${sort}`} t={tAlertPanelSorts} />
                </CatTypography>
              </CatMenuItem>
              {index % 2 === 1 && !isLastItem && <CatMenuDivider />}
            </div>
          );
        })}
      </CatMenu>
    </>
  );
}

export default AlertPanelSort;
