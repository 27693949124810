import { Priority, ProductType, ResponseStatuses } from '../types';

export const extraFilters = ['unresponded', 'responded', 'responsible', 'receive'] as const;
export type ExtraFilter = (typeof extraFilters)[number];

export const alertPanelSortings = [
  'creationDateByNewest',
  'creationDateByOldest',
  'lastUpdateByNewest',
  'lastUpdateByOldest',
  'priorityByHighest',
  'priorityByLowest'
] as const;
export type AlertPanelSort = (typeof alertPanelSortings)[number];

export type AlertPanelFilter = {
  branches: string[];
  extraFilters: ExtraFilter[];
  priorities: Priority[];
  products: ProductType[];
  sort: AlertPanelSort;
  statuses: ResponseStatuses[];
};

// TODO: Remove after backend implement all filter and sorts
export type AvailableAlertPanelFilters = Omit<AlertPanelFilter, 'sort' | 'extraFilters'>;

export type AlertStatusCounts = Record<ResponseStatuses, number>;
